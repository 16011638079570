import API from "@aws-amplify/api";
import sortBy from "lodash/sortBy";
import GetKeywordList from "./Shared Scripts/RefreshKeywordData";
import GetCollegeList from "./Shared Scripts/RefreshCollegeData";
// export const addItem = payload => {
// 	return {
// 	  type: "ADD_ITEM",
// 	  payload
// 	};
//   };

export async function GetUnique(data, fieldName) {
  const unique = sortBy(
    Array.from(new Set(data.map((row) => row[fieldName])))
  ).map(function (org) {
    return {
      value: org,
      text: org,
      key: org,
    };
  });
  return unique;
}

export async function BuildKeywordInfo() {
  var paramsKeyword = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Keyword",
      },
      ExpressionAttributeNames: { data: "data" },
      ProjectionExpression: "pk,data",
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const responseData = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsKeyword,
    }
  );
  //var FieldNames = ["pk,data"];
  //var Data = [];
  //FieldNames.forEach(async function (element) {
  //	const UniqueItems = await GetUnique(responseData.Items, element);
  //		Data[`${element}`] = UniqueItems;
  //	});
  return responseData;
}
export async function GetKeywordData() {
  if (JSON.parse(localStorage.getItem("keyworddata")) !== null) {
    const responsearray = JSON.parse(localStorage.getItem("keyworddata"));
    return responsearray;
  } else {
    const responseData = await GetKeywordList();
    return responseData;
  }
}
export async function GetKeywordInfo(keywordid) {
  var paramsKeyword = {
    operation: "get",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Key: {
        pk: keywordid,
        sk: "Keyword",
      },
      ExpressionAttributeNames: { data: "data" },
      ProjectionExpression: "pk,data",
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsKeyword,
    }
  );
  return response;
}

export async function BuildCollegeInfo() {
  var paramsCollege = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "College",
      },
      ExpressionAttributeNames: { data: "data" },
      ProjectionExpression: "pk,data",
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const responseData = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsCollege,
    }
  );
  //var FieldNames = ["pk,data"];
  //var Data = [];
  //FieldNames.forEach(async function (element) {
  //	const UniqueItems = await GetUnique(responseData.Items, element);
  //		Data[`${element}`] = UniqueItems;
  //	});
  return responseData;
}
export async function GetCollegeData() {
  if (JSON.parse(localStorage.getItem("collegedata")) !== null) {
    const responsearray = JSON.parse(localStorage.getItem("collegedata"));
    return responsearray;
  } else {
    const responseData = await GetCollegeList();
    return responseData;
  }
}
export async function GetCollegeInfo(collegeid) {
  var paramsCollege = {
    operation: "get",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Key: {
        pk: collegeid,
        sk: "College",
      },
      ExpressionAttributeNames: { data: "data" },
      ProjectionExpression: "pk,data",
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsCollege,
    }
  );
  return response;
}
export async function BuildProgramInfo() {
  var paramsPrograms = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Program",
      },
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const responseData = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsPrograms,
    }
  );
  return responseData;
}
export async function BuildDepartmentInfo() {
  var paramsDept = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Department",
      },
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const responseData = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsDept,
    }
  );
  return responseData;
}
export async function BuildStudentInfo() {
  var paramsStudents = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Student",
      },
    },
  };
  //"pk,sk,ATOAccount,Address1,Address2,BusAddress1,BusAddress2,BusCity,BusState,BusZIP,City,ContactNotes,DesignatedContact,EmailAddress,EmailAddress2,FirstName,Honorific,IsActive,IsDeleted,IsOrganization",

  const responseData = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsStudents,
    }
  );
  return responseData;
}
