import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Box } from "@material-ui/core";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChipsArray(props) {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState(props.allkeywords);
  const handleToggle = (chipToToggle) => () => {
    const currentIndex = chipData.indexOf(chipToToggle);
    var newchips = chipData;
    function handleselectchange(index) {
      props.receiveDataFromChild(index);
      //if its class based use this in front of props like this this.props.propName
    }
    if (chipData[currentIndex].Selected) {
      newchips[currentIndex].Selected = false;
    } else {
      newchips[currentIndex].Selected = true;
    }
    console.log(newchips);
    setChipData(newchips);
    handleselectchange(newchips);
  };

  return (
    <Box component="ul" className={classes.root}>
      {chipData.map((data) => {
        let icon;
        return (
          <li key={data.key}>
            <Chip
              color={data.Selected ? "primary" : "secondary"}
              icon={icon}
              label={data.KeyName}
              onClick={handleToggle(data)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Box>
  );
}
