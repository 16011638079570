import React, { Component, createRef } from "react";
import API from "@aws-amplify/api";
import "../styles/index.css";
import Edit from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import { Form } from "semantic-ui-react";
import moment from "moment";
import { GetCurrentDate, generateRowId } from "../../../util/util";
import Notifier, { openSnackbar } from "../../../Common/components/Notifier";
import { BuildDepartmentInfo } from "../../../actions-index";
import { Link } from "react-router-dom";
import { GetDepartmentData } from "../action";

export default class AddDepartment extends Component {
  _isMounted = false;
  _redirect = "/Departments";

  constructor(props) {
    super(props);
    this.showNotifier = this.showNotifier.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.addDepartment = this.addDepartment.bind(this);
    this.state = {
      currentDepartment: {
        pk: null,
        data: "",
      },
      inputRef: createRef(),
      isLoading: true,
      message: "",
    };
    this.user = sessionStorage.getItem("UserName");
  }

  async componentDidMount() {
    this._isMounted = true;

    await BuildDepartmentInfo().then((response) =>
      this.setState({ DepartmentInfo: response, isLoading: false })
    );
  }

  handleChange(e, { id, value }) {
    this.setState(
      {
        currentDepartment: Object.assign({}, this.state.currentDepartment, {
          [id]: value,
        }),
      },
      () => {}
    );
  }

  handleDateChange(e, value) {
    this.setState(
      {
        currentDepartment: Object.assign({}, this.state.currentDepartment, {
          [e]: moment(value).format("YYYY-MM-DD"),
        }),
      },
      () => {}
    );
  }

  handleCheckboxChange(e, { id }) {
    this.setState(
      {
        currentDepartment: Object.assign({}, this.state.currentDepartment, {
          [id]: e.target.checked,
        }),
      },
      () => {}
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showNotifier(messageString) {
    openSnackbar({ message: messageString });
  }

  getRedirect() {
    return this._redirect;
  }

  async addDepartment(e) {
    const currentDepartment = this.state.currentDepartment;

    if (currentDepartment.data.trim().length > 0) {
      var paramsAdd = {
        operation: "create",
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        payload: {
          Item: {
            pk: "department-" + generateRowId(4),
            sk: "Department",
            data: currentDepartment.data,
            LastModifiedBy: this.user,
            LastModifiedDate: GetCurrentDate(),
          },
          TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
          //ReturnValues: "ALL_OLD",
        },
      };
      await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
        body: paramsAdd,
      }).then((response) => {
        GetDepartmentData().then((responseData) =>
          this.setState({
            data: responseData,
            isLoading: false,
            responseData: responseData,
          })
        );

        this.showNotifier("Department Added");
        this._redirect = "/Departments";
      });
    } else {
      this.showNotifier("Please type in a valid department");
      this.setState({ redirect: false });
      this._redirect = "/Department/AddDepartment";
    }
  }

  render() {
    const currentDepartment = this.state.currentDepartment;
    const departmentRedirect = this.getRedirect();

    if (this.state.isLoading) {
      return <Typography variant="h1">Loading Department...</Typography>;
    } else {
      return currentDepartment ? (
        <div className="edit-form">
          <Typography variant="h1">
            <Edit />
            Add Department
          </Typography>
          {
            <Form onSubmit={this.addDepartment} autoComplete="nope">
              <hr className="scarlet"></hr>
              <Form.Group>
                <Form.Input
                  label="Department"
                  placeholder="Department"
                  className="form-control"
                  id="data"
                  value={currentDepartment.value}
                  onChange={this.handleChange}
                  width={6}
                />
              </Form.Group>
              <br />
              <Form.Group inline>
                <Form.Button type="submit" className="badge badge-success">
                  Add Department
                </Form.Button>
                <Link to={{ pathname: "/departments" }}>
                  <Form.Button>Cancel</Form.Button>
                </Link>
              </Form.Group>
              <Link to={{ pathname: `${departmentRedirect}` }}>
                <Notifier />
              </Link>
            </Form>
          }
        </div>
      ) : null;
    }
  }
}
