import { createTheme } from "@material-ui/core";
import { fonts } from "./typography";
import elevations from "./elevations";
import mapValues from "lodash/mapValues";
import { typeColor } from "../../styles/palette";
import { darken, lighten } from "@material-ui/core/styles";

const defaultTheme = createTheme();

const navBackground = () => ({
  flexGrow: 1,
  zIndex: defaultTheme.zIndex.drawer + 1,
});

const focus = {
  root: {
    "&:focus": {
      outline: "-webkit-focus-ring-color auto 5px",
    },
  },
};

const overrides = (darkMode = false, elevation = {}) => {
  const typePalette = typeColor[darkMode ? "darkMode" : "standard"];
  const shade = darkMode ? "#f5f5f5" : "#000000";
  return {
    MuiFormLabel: {
      root: {
        color: typePalette.default,
        "&.$Mui-focused": {
          color: typePalette.default,
        },
        color: "#bb0000",
        fontSize: "medium",
        // margin: "0 0 .28571429rem 0",
        // paddingLeft: ".5em",
        // padding: ".78571429em 2.1em .78571429em 1em",
        // paddingRight: ".5em",
        fontWeight: "bold"
      },
    },
    MuiFormControl:{
      root:{
        paddingRight: ".5em",
        paddingLeft: ".5em",
        // padding: ".78571429em 2.1em .78571429em 1em"
      }
    },
    MuiFormControlLabel: {
      label: {
        color: typePalette.default,
        "&.$Mui-disabled": {
          color: darkMode ? darken(shade, 0.4) : lighten(shade, 0.4),
        },
      },
    },
    MuiToggleButton: {
      label: {
        color: `var(--root-text-color)`,
        "&.$Mui-disabled": {
          color: darkMode ? darken(shade, 0.4) : lighten(shade, 0.4),
        },
      },
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
        "&.$Mui-disabled": {
          backgroundColor: darkMode ? lighten(shade, 0.4) : darken(shade, 0.4),
        },
        "&.$Mui-selected": {
          backgroundColor: `rgba(${
            darkMode ? `245, 245, 245` : `0, 0, 0`
          }, 0.1)`,
          border: "1px solid #bb0000",
        },
      },
    },
    MuiBadge: {
      root: {
        padding: "10px",
      },
    },
    MuiCheckbox: {
      root: darkMode
        ? {
            color: darken(shade, 0.4),
          }
        : {},
    },
    MuiSelect: {
      select: {
        "&:focus": {
          color: `rgb(${darkMode ? `0, 0, 0` : `245, 245, 245`})`,
          backgroundColor: `rgb(${darkMode ? `245, 245, 245` : `0, 0, 0`})`,
        },
      },
    },
    MuiDataGrid: {
      root: {
        // backgroundColor: `rgba(${
        // 	darkMode ? `245, 245, 245` : `0, 0, 0`
        // }, 0.1)`,
        color: `var(--root-text-color)`,
        // row:{
        // 	"&.$Mui-selected": {
        // 		backgroundColor: `rgba(${
        // 			darkMode ? `245, 245, 245` : `0, 0, 0`
        // 		}, 0.1)`,
        // 		border: "1px solid #bb0000",
        // 	},
        // },
      },
    },
    ReactModal__Content: {
      backgroundColor: `rgba(${darkMode ? `0, 0, 0` : `245, 245, 245`})`,
    },
    MuiInputBase: {
      // head: {
      //     color: `var(--root-text-color)`
      // },
      // adornedEnd:{
      // 	backgroundColor: darkMode ? lighten(shade, .4) : darken(shade, .4)
      // },
      input: {
        color: `rgb(${darkMode ? `0, 0, 0` : `245, 245, 245`})`,
        backgroundColor: `rgb(${darkMode ? `245, 245, 245` : `0, 0, 0`})`,
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
        color: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`})`,
      },
    },
    MuiIconButton: {
      root: {
        color: typePalette.action,
      },
    },
    // MuiButton: {
    //   ...(darkMode
    //     ? {
    //         contained: {
    //           "&.$Mui-disabled": {
    //             color: "rgba(245, 245, 245, 0.3)",
    //             boxShadow: "none",
    //             backgroundColor: "rgba(245, 245, 245, 0.12)",
    //           },
    //         },
    //       }
    //     : {}),
    // },
    MuiInputLabel: {
      root: darkMode
        ? {
            color: `var(--root-text-color)`,
          }
        : {},
      formControl: darkMode
        ? {
            color: `var(--root-text-color)`,
            transform: "inherit",
            left:"7px",
          }
        : { transform: "inherit", left:"7px", },
        
    },
    MuiButtonBase: focus,
    MuiPaper: {
      outlined: darkMode
        ? {
            border: "1px solid rgba(255,255,255, 0.12)",
          }
        : {},
      root: {
        color: shade,
      },
      ...(darkMode
        ? mapValues(elevations, (item) => ({ ...item, boxShadow: "none" }))
        : {}),
    },
    MuiTypography: {
      root: {
        color: shade,
      },
      colorTextSecondary: {
        color: typePalette.secondary,
      },
      h2: {
        fontSize: `1.125rem`,
        fontWeight: `500`,
        lineHeight: `1.6`,
        "@media (min-width: 1280px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:960px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:600px)": {
          fontSize: `1.25rem`,
        },
      },
      h1: {
        fontSize: `1.125rem`,
        fontWeight: `500`,
        lineHeight: `1.6`,
        "@media (min-width: 1280px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:960px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:600px)": {
          fontSize: `1.25rem`,
        },
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: typePalette.secondary,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        fill: typePalette.action,
      },
      colorSecondary: {
        fill: typePalette.secondary,
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": fonts,
      },
    },
    MuiListSubheader: {
      root: {
        color: shade,
      },
    },
    MuiAppBar: {
      root: {
        ...navBackground(),
      },
    },
    Mui: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(187, 0, 0, 0.7)",
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        ...navBackground(),
        ...(darkMode ? elevations[`elevation${elevation.navigation}`] : {}),
        overflow: "hidden",
        position: "fixed",
        bottom: 0,
        width: "100%",
      },
    },
    MuiNativeSelect: {
      root: {
        color: shade,
      },
      select: {
        color: shade,
        "&.option": darkMode ? elevations[`elevation${elevation.high}`] : {},
      },
      selectMenu: {
        color: shade,
      },
      icon: {
        color: shade,
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid ${shade}`,
        },
        "&:before": {
          borderBottom: `1px solid ${shade}`,
        },
        "&:hover&:after": {
          borderBottom: `1.5px solid ${shade}`,
        },
        "&:hover&:before": {
          borderBottom: `1.5px solid ${shade}`,
        },
      },
    },
    MuiDivider: {
      root: darkMode
        ? {
            backgroundColor: "rgba(245,245,245, 0.12)",
          }
        : {},
    },
    MuiListItem: {
      divider: darkMode
        ? {
            borderBottomColor: "rgba(255,255,255, 0.12)",
          }
        : {},
    },
    MuiListItemIcon: {
      root: {
        color: shade,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: darkMode
        ? {
            borderColor: "rgba(255,255,255, 0.12)",
          }
        : {},
    },
    MuiSlider: {
      markLabel: darkMode
        ? {
            color: "rgba(255,255,255, 0.50)",
          }
        : {},
      markLabelActive: darkMode
        ? {
            color: "rgba(255,255,255, 0.90)",
          }
        : {},
    },
    MuiSkeleton: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
      },
    },
    MuiTable: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
      },
    },
    MuiTableFooter: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
      },
    },
    // MuiTableCell: {
    //     head: {
    //         color: `var(--root-text-color)`
    //     },
    //     body: {
    //         color: `var(--root-text-color)`
    //     }
    // },
    // MuiTableRow:{
    //     root:{
    //         backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
    //         // '&.$Mui-disabled': {
    //         //     backgroundColor: darkMode ? lighten(shade, .4) : darken(shade, .4)
    //         // },
    //         // backgroundColor: darkMode ? `rgba(0,0,0)` : `rgba(0,0,0,0.1)`,
    //         color: `var(--root-text-color)`
    //     }

    // },
    MuiTableBody: {
      root: {
        backgroundColor: `rgba(${darkMode ? `0, 0, 0` : `245, 245, 245`}, 0.1)`,
        // '&.$Mui-disabled': {
        //     backgroundColor: darkMode ? lighten(shade, .4) : darken(shade, .4)
        // },
        // backgroundColor: darkMode ? `rgba(0,0,0)` : `rgba(0,0,0,0.1)`,
        color: `var(--root-text-color)`,
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#BB0000",
        color: "#fff",
      },
      body: {
        backgroundColor: `rgba(${darkMode ? `0, 0, 0` : `245, 245, 245`})`,
        color: `var(--root-text-color)`,
      },
    },
    MuiPaperInGridRoot: {
      flexGrow: 1,
    },
    MuiPaperInGrid: {
      root: {
        // padding: theme.spacing(2),
        textAlign: "center",
        color: typePalette.secondary,
      },
      textAlign: "center",
      color: typePalette.secondary,
    },
    default: {
      color: "#000",
    },
  };
};

export default overrides;
