import React, { Fragment, useEffect } from "react";
import "./styles/index.css";


function Home(props, userinfo) {
	const { generateTitle } = props;
	useEffect(() => {
		generateTitle("SELC Portal");
	}, [generateTitle]);
	return (
		<Fragment>
			{" "}
			<h1 className="h-accessible-text">SELC Portal</h1>{" "}
		</Fragment>
	);
	// else return <Fragment><h1 className="h-accessible-text">SELC Portal</h1></Fragment>
}

export default Home;
