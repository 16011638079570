import React, { Component } from "react";
import API from "@aws-amplify/api";
import "../styles/index.css";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import { Form, Confirm } from "semantic-ui-react";
import moment from "moment";
import { GetCurrentDate } from "../../../util/util";
import Notifier, { openSnackbar } from "../../../Common/components/Notifier";
import { GetDepartmentByDepartmentID } from "../action";
import { Link } from "react-router-dom";

export default class EditDepartment extends Component {
  _isMounted = false;
  _redirect = "/Departments";

  constructor(props) {
    super(props);
    props.generateTitle();
    this.departmentid = this.props.departmentid;
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.updateDepartment = this.updateDepartment.bind(this);
    this.deleteDepartment = this.deleteDepartment.bind(this);
    this.showNotifier = this.showNotifier.bind(this);
    this.state = {
      currentDepartment: {
        pk: null,
        data: "",
      },
      isLoading: true,
      updated: false,
      message: "",
      open: false,
    };
    this.user = sessionStorage.getItem("UserName");
  }

  async componentDidMount() {
    this._isMounted = true;

    await GetDepartmentByDepartmentID(this.props.departmentid).then(
      (response) =>
        this.setState({ currentDepartment: response, isLoading: false })
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showNotifier(messageString) {
    openSnackbar({ message: messageString });
  }

  getRedirect() {
    return this._redirect;
  }

  handleChange(e, { id, value }) {
    this.setState(
      {
        currentDepartment: Object.assign({}, this.state.currentDepartment, {
          [id]: value,
        }),
      },
      () => {}
    );
  }
  handleDateChange(e, value) {
    this.setState(
      {
        currentDepartment: Object.assign({}, this.state.currentDepartment, {
          [e]: moment(value).format("YYYY-MM-DD"),
        }),
      },
      () => {}
    );
  }
  handleCheckboxChange(e, { id }) {
    this.setState(
      {
        currentDepartment: Object.assign({}, this.state.currentDepartment, {
          [id]: e.target.checked,
        }),
      },
      () => {}
    );
  }

  async updateDepartment() {
    const currentDepartment = this.state.currentDepartment;

    if (currentDepartment.data.trim().length > 0) {
      var paramsUpdate = {
        operation: "update",
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        payload: {
          TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,

          Key: {
            pk: this.departmentid,
            sk: "Department",
          },

          UpdateExpression:
            "set  #data=:d, LastModifiedBy=:user, LastModifiedDate=:date",
          ExpressionAttributeNames: {
            "#data": "data",
          },

          ExpressionAttributeValues: {
            ":d": currentDepartment.data,
            ":user": this.user,
            ":date": GetCurrentDate(),
          },

          ReturnValues: "UPDATED_NEW",
        },
      };

      API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
        body: paramsUpdate,
      }).then(() => {
        this.setState({
          currentDepartment: this.state.currentDepartment,
          isLoading: false,
        });

        this.showNotifier("Department Updated");
        this.setState({ redirect: true });
        this._redirect = "/Departments";
      });
    } else {
      this.showNotifier("Please type in a valid department");
      this.setState({ redirect: false });
      this._redirect = "/Department/" + this.departmentid;
    }
  }

  deleteDepartment() {
    var paramsDelete = {
      operation: "delete",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      payload: {
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        Key: {
          pk: this.departmentid,
          sk: "Department",
        },
      },
    };

    API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
      body: paramsDelete,
    }).then(() => {
      this.setState({
        currentDepartment: this.state.currentDepartment,
        isLoading: false,
      });

      this._redirect = "/Departments";
      this.showNotifier("Department Deleted!");
    });
  }

  show = () => this.setState({ open: true });
  handleConfirm = () => this.setState({ open: false });
  handleCancel = () => this.setState({ open: false });

  render() {
    if (this.state.isLoading) {
      return <Typography variant="h1">Loading Department...</Typography>;
    } else {
      const currentDepartment = this.state.currentDepartment;
      const departmentRedirect = this.getRedirect();

      return currentDepartment ? (
        <div className="edit-form">
          <Typography variant="h1">
            <EditIcon />
            Edit Department
          </Typography>
          {
            <Form>
              <hr className="scarlet"></hr>
              <Form.Group>
                <Form.Input
                  label="Department"
                  placeholder="Department"
                  className="form-control"
                  id="data"
                  value={currentDepartment.data}
                  onChange={this.handleChange}
                  width={6}
                />
              </Form.Group>
              <br />
              <Form.Group inline>
                <Form.Button
                  type="submit"
                  onClick={this.updateDepartment}
                  className="badge badge-success"
                >
                  Update
                </Form.Button>

                <Form.Button className="badge badge-danger" onClick={this.show}>
                  Delete
                </Form.Button>
                <Confirm
                  id="confirmDialogBox"
                  open={this.state.open}
                  header="Deletions can not be undone"
                  cancelButton="Never mind"
                  confirmButton="Let's do it"
                  onCancel={this.handleCancel}
                  onConfirm={(this.handleConfirm, this.deleteDepartment)}
                />
                <Link to={{ pathname: "/Departments" }}>
                  <Form.Button>Cancel</Form.Button>
                </Link>
              </Form.Group>
              <Link to={{ pathname: `${departmentRedirect}` }}>
                <Notifier />
              </Link>
            </Form>
          }
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Department...</p>
        </div>
      );
    }
  }
}
