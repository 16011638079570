import React, { Component, createRef } from "react";
import API from "@aws-amplify/api";
// import "../styles/index.css";
import Edit from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import { Form } from "semantic-ui-react";
import moment from "moment";
import { GetCurrentDate, generateRowId } from "../../../util/util";
import Notifier, { openSnackbar } from "../../../Common/components/Notifier";
import { BuildStudentInfo, BuildProgramInfo } from "../../../actions-index";
import { Link } from "react-router-dom";
import { GetStudentData } from "../actions";

export default class AddStudent extends Component {
  _isMounted = false;
  _redirect = "/Students";

  constructor(props) {
    super(props);
    this.showNotifier = this.showNotifier.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.addStudent = this.addStudent.bind(this);
    this.state = {
      currentStudent: {
        pk: null,
        data: "",
        FirstName: "",
        LastName: "",
        GradYear: "",
        Program: "",
        ImpactInfo: "",
      },
      programList: [],
      inputRef: createRef(),
      isLoading: true,
      message: "",
    };
    this.user = sessionStorage.getItem("UserName");
  }

  async componentDidMount() {
    this._isMounted = true;
    await BuildProgramInfo().then((response) =>
      this.setState({ programList: response })
    );
    console.log(this.state.programList);
    await BuildStudentInfo().then((response) =>
      this.setState({ StudentInfo: response, isLoading: false })
    );
  }

  handleChange(e, { id, value }) {
    this.setState(
      {
        currentStudent: Object.assign({}, this.state.currentStudent, {
          [id]: value,
        }),
      },
      () => {}
    );
  }

  handleDateChange(e, value) {
    this.setState(
      {
        currentStudent: Object.assign({}, this.state.currentStudent, {
          [e]: moment(value).format("YYYY-MM-DD"),
        }),
      },
      () => {}
    );
  }

  handleCheckboxChange(e, { id }) {
    this.setState(
      {
        currentStudent: Object.assign({}, this.state.currentStudent, {
          [id]: e.target.checked,
        }),
      },
      () => {}
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showNotifier(messageString) {
    openSnackbar({ message: messageString });
  }

  getRedirect() {
    return this._redirect;
  }

  async addStudent() {
    console.log(this.state);
    const currentStudent = this.state.currentStudent;
    console.log(currentStudent);
    const namestring =
      "firstname=" +
      currentStudent.FirstName +
      "#lastname=" +
      currentStudent.LastName;

    if (namestring.trim().length > 0) {
      var paramsAdd = {
        operation: "create",
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        payload: {
          Item: {
            pk: "student-" + generateRowId(4),
            sk: "Student",
            data: namestring,
            FirstName: currentStudent.FirstName,
            LastName: currentStudent.LastName,
            Program: currentStudent.Program,
            GradYear: currentStudent.GradYear,
            ImpactInfo: currentStudent.ImpactInfo,
            LastModifiedBy: this.user,
            LastModifiedDate: GetCurrentDate(),
          },
          TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
          //ReturnValues: "ALL_OLD",
        },
      };
      await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
        body: paramsAdd,
      }).then((response) => {
        GetStudentData().then((responseData) =>
          this.setState({
            data: responseData,
            isLoading: false,
            responseData: responseData,
          })
        );

        this.showNotifier("Student Added");
        this._redirect = "/Students";
      });
    } else {
      this.showNotifier("Please type in a valid student");
      this.setState({ redirect: false });
      this._redirect = "/Student/AddStudent";
    }
  }

  render() {
    const studentRedirect = this.getRedirect();

    if (this.state.isLoading) {
      return <Typography variant="h1">Loading Student...</Typography>;
    } else {
      var { currentStudent } = this.state;
      console.log(this.state.currentStudent);
      const programList = this.state.programList.Items.map((prog) => ({
        value: prog.pk,
        text: prog.data,
        key: prog.pk,
      }));
      return currentStudent ? (
        <div className="edit-form">
          <Typography variant="h1">
            <Edit />
            Add Student
          </Typography>
          {
            <Form>
              <hr className="scarlet"></hr>
              <Form.Group>
                <Form.Input
                  label="Student First Name"
                  placeholder="Student First Name"
                  className="form-control"
                  id="FirstName"
                  value={currentStudent.FirstName}
                  onChange={this.handleChange}
                  width={6}
                />
                <Form.Input
                  label="Student Last Name"
                  placeholder="Student Last Name"
                  className="form-control"
                  id="LastName"
                  value={currentStudent.LastName}
                  onChange={this.handleChange}
                  width={6}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Select
                  label="Program"
                  name="Program"
                  search
                  className="form-control"
                  value={currentStudent.Program}
                  options={programList}
                  placeholder="Program"
                  onChange={this.handleChange}
                  id="Program"
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Graduation Year"
                  type="number"
                  placeholder="Graduation Year"
                  className="form-control"
                  id="GradYear"
                  value={currentStudent.GradYear}
                  onChange={this.handleChange}
                  width={2}
                />
              </Form.Group>
              <Form.Group inline>
                <Form.Button
                  type="submit"
                  onClick={this.addStudent}
                  className="badge badge-success"
                >
                  Add Student
                </Form.Button>
                {/* <Form.Button type="submit" className="badge badge-success">
                  Add Student
                </Form.Button> */}
                <Link to={{ pathname: "/students" }}>
                  <Form.Button>Cancel</Form.Button>
                </Link>
              </Form.Group>
              <Link to={{ pathname: `${studentRedirect}` }}>
                <Notifier />
              </Link>
            </Form>
          }
        </div>
      ) : null;
    }
  }
}
