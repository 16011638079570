import API from "@aws-amplify/api";
import { GetCurrentDateTime, generateRowId } from "../../util/util";

export async function GetProgramByID(progid) {
  var program = {};
  var paramsPrograms = {
    operation: "get",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Key: {
        pk: progid,
        sk: "Program",
      },
    },
  };
  await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
    body: paramsPrograms,
  }).then((response) => (program = response.Item));
  return program;
}
export async function GetColleges() {
  var paramsColleges = {
    operation: "read",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "College",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsColleges,
    }
  );
  return response.Items;
}
export async function GetDepartments() {
  var paramsDept = {
    operation: "read",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Department",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsDept,
    }
  );
  return response.Items;
}
export async function GetPrograms() {
  var paramsProg = {
    operation: "read",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Program",
      },
    },
  };

  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsProg,
    }
  );
  return response.Items;
}

export async function AddProgram(program, user) {
  var paramsUpdate = {
    operation: "create",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Item: {
        pk: "program-" + generateRowId(4),
        sk: "Program",
        data: program.data,
        LastModifiedBy: user,
        LastModifiedDate: GetCurrentDateTime(),
      },
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      ReturnValues: "ALL_OLD",
    },
  };

  await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
    body: paramsUpdate,
  });

  return;
}
export async function GetKeywords(programid) {
  var paramsProg = {
    operation: "read",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      KeyConditionExpression: "sk = :progid and #data = :objecttype",
      ExpressionAttributeValues: {
        ":progid": programid,
        ":objecttype": "KeywordProgram",
      },
      ExpressionAttributeNames: {
        "#data": "data",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsProg,
    }
  );
  await response.Items.forEach(async (element) => {
    var paramsKeyword = {
      operation: "get",
      tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      payload: {
        Key: {
          pk: element.pk,
          sk: "Keyword",
        },
      },
    };
    var elem = await API.post(
      process.env.REACT_APP_SELC_PORTAL_API_NAME,
      "/items",
      {
        body: paramsKeyword,
      }
    );
    if (elem.Item) {
      element.KeyName = elem.Item.data;
      element.key = element.pk;
      element.Selected = true;
    }
  });
  return response.Items;
}
export async function GetKeywordData() {
  var paramsKeyword = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Keyword",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsKeyword,
    }
  );
  response.Items.map(
    (e) => ((e.KeyName = e.data), (e.key = e.pk), (e.Selected = false))
  );
  return response.Items;
}
export async function GetCareerPaths(programid) {
  var paramsProg = {
    operation: "read",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      KeyConditionExpression: "sk = :objecttype and #data = :progid",
      ExpressionAttributeValues: {
        ":progid": programid,
        ":objecttype": "CareerPath",
      },
      ExpressionAttributeNames: {
        "#data": "data",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsProg,
    }
  );
  for (var elem of response.Items){
    elem.CareerName = elem.Name;
    elem.isSelected = "true";
  }
  return response.Items;
}
export async function GetCareerPathsData() {
  var paramsKeyword = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "CareerPath",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsKeyword,
    }
  );
  response.Items.map(
    (e) => ((e.CareerName = e.data), (e.key = e.pk), (e.Selected = false))
  );
  return response.Items;
}
