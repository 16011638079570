import React, { Component } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { Form } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { GetKeywordData } from "./action";


export default class Keywords extends Component{


    constructor(props){
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            query: "",
            bgcolor: "#bdbdbd",
            alphabet: "",
            data: [],
            filteredData: [],
            responseData: {
                data: "",
                pk: "",
                namedotn: "",
              },
              isLoading: true,
            };
        }
        async componentDidMount(){
        
            await GetKeywordData().then((responseData) =>
            this.setState({
                data: responseData,
                isLoading: false,
                responseData: responseData,
            })
        );
    }
    handleInputChange = (event) => {
        this.setState(
            {
                query: event.target.value
            },
            () => {
              this.filterArray();
            }
          );
    };
    handleAlphabetChange = (event) => {
      this.setState(
        {
          query: event.target.value,
        },
        () => {
          this.filterArray();
        }
      );
    };

   
    onAlphabetClick = (e) => {
        this.setState({ alphabet: this.state.alphabet === e ? "" : e }, () => {
          this.filterArray();
        });
      };
      filterArray = () => {
        const { query, alphabet, data } = this.state;
        let searchString = "";
        let searchStringArr = query.split(" ");
        searchStringArr.forEach((element) => {
          searchString = searchString + "(?=.*" + element + ")";
        });
        const regexp = new RegExp(searchString, "i");
        let filterResponseData = data;
        if (query.length > 2 || alphabet) {
          filterResponseData = filterResponseData.filter((element) => {
            return element.data.toLowerCase().search(regexp) > -1;
          });
          if (alphabet) {
            filterResponseData = filterResponseData.filter(
              (element) =>
                element.data.charAt(0).toLowerCase() === alphabet.toLowerCase()
            );
          }
          this.setState({
            responseData: filterResponseData,
          });
        } else {
          this.setState({ responseData: data });
        }
      };
      prepareAlphabets = () => {
        let result = [];
        for (let i = 65; i < 91; i++) {
          result.push(
            <Avatar
              onClick={this.onAlphabetClick.bind(this, String.fromCharCode(i))}
              key={i}
              value={String.fromCharCode(i)}
              style={{
                backgroundColor:
                  String.fromCharCode(i) === this.state.alphabet
                    ? "#bb0000"
                    : "#bdbdbd",
              }}
            >
              {String.fromCharCode(i)}
            </Avatar>
          );
        }
        return result;
 }; 

 render() {
    const ListKeywords = React.lazy(() => import("./components/ListKeywords"));
    const scarletWhite = {
      backgroundColor: "#BB0000",
      color: "#fff",
    };
    console.log(this.state);
    const { responseData } = this.state;
    if (this.state.isLoading) {
      return (
        <CircularProgress
          className="mt-2"
          width={60}
          height={60}
          color="primary"
        />
      );
    } else {
      return (
        <div className="edit-form">
          <Typography variant="h2">
            <EditIcon />
            Search Keywords
          </Typography>
          <Link to={"/addkeyword"}>
            <button style={scarletWhite}>Add Keyword</button>
          </Link>
          <AvatarGroup max={30}>{this.prepareAlphabets()}</AvatarGroup>
          {
            <Form>
              <Form.Input
                placeholder="Search for Keyword..."
                value={this.state.query}
                onChange={this.handleInputChange}
              />
            </Form>
          }
          {responseData.length > 0 ? (
            <ListKeywords data={responseData} />
          ) : (
            <div></div>
          )}
        </div>
      );
    }
  }
}