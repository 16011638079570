import API from "@aws-amplify/api";
import orderBy from "lodash/orderBy";
export default async function GetCollegeList() {
	var paramsCollege = {
		operation: "read",
		tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
		payload: {
			IndexName: "sk-data-index",
			KeyConditionExpression: "sk = :objecttype",
			ExpressionAttributeValues: {
				":objecttype": "College",
			},
			ExpressionAttributeNames: { "data": "data" },
			ProjectionExpression:
				"pk,data",
		},
	};
	const response = await API.post(
		process.env.REACT_APP_SELC_PORTAL_API_NAME,
		"/items",
		{
			body: paramsCollege,
		}
	);
	const responseData = orderBy(
		response.Items,
		["data"],
		["desc", "asc"]
	);
	responseData.map(function (tcid) {
		Object.keys(tcid).forEach((key) => {
			if (tcid[key] === "NULL") {
				tcid[key] = "";
			}
		});
		return tcid;
	});
	localStorage.setItem("collegedata", JSON.stringify(responseData));
	return responseData;
}
