import { Auth } from "aws-amplify";

export function redirectToLogIn() {
  const { oauth } = Auth.configure();
  Auth.federatedSignIn({ customProvider: oauth.idp });
}
export function GetCurrentDate(separator = "-") {
  let newDate = new Date();
  let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}
export async function GetCurrentDateTime(separator = "-") {
  let newDate = new Date().toLocaleString() + "";
  return newDate.toString();
}
export function StringToBoolean(value) {
  if (typeof value === "string") {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}
export function generateRowId(shardId /* range 0-64 for shard/slot */) {
  var CUSTOMEPOCH = 1300000000000;
  var ts = new Date().getTime() - CUSTOMEPOCH; // limit to recent
  var randid = Math.floor(Math.random() * 512);
  ts = ts * 64; // bit-shift << 6
  ts = ts + shardId;
  return ts * 512 + randid;
}
