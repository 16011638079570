import {
    SET_USER,
    USER_STATUS,
} from '../actions-index';
import { isEmpty } from 'lodash';

export function authentication(state = {}, action = {}) {
    const { payload } = action
    switch (action.type) {
        case SET_USER:
            return {
                user: payload,
                status: !isEmpty(payload) ? 'success' : state.status
            };
        case USER_STATUS:
            return {
                ...state,
                status: action.payload
            };
        default:
            return state
    }
}