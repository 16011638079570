import React, { useRef, useEffect, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  ButtonBase,
  Container,
} from "@material-ui/core/";
import useStyles from "../../styles/theme";
import "../styles/index.css";
import SkipTo from "../../../Common/components/SkipTo";
import buckeyeScarletWhite from "../../../Common/BuckeyeLeaf-Scarlet.svg";
import buckeyeSmoothScarletWhite from "../../../Common/BuckeyeSmooth-Scarlet.svg";
import PropTypes from "prop-types";

function AppBar(props) {
  const {
    darkMode = false,
    determineHeight,
    className,
    elevation,
    user,
  } = props;
  const ref = useRef({ current: { clientHeight: null } });

  useEffect(() => {
    determineHeight(ref.current.clientHeight);
  }, [ref, determineHeight]);

  const classes = useStyles();

  return (
    <MuiAppBar
      ref={ref}
      elevation={elevation}
      className={className}
      position="sticky"
      color="inherit"
    >
      <SkipTo />
      <Toolbar style={{ borderBottom: "solid 3px #bb0000" }}>
          {/* <ButtonBase aria-label="Navigate to home" component={RouterLink} to="/"> */}
          {/* <img className="buckeyeNut" alt="Sustainability Institute Logo" src={darkMode ? silogo : silogo} />  */}
          {/* </ButtonBase> */}
          <ButtonBase aria-label="Navigate to Home" href="https://si.osu.edu/" className="d-flex align-items-center mr-auto">
            <Typography
              color="secondary"
              classes={{ root: classes.title }}
              className="pl-1 d-flex flex-column"
              variant="h1"
              component="span"
              noWrap
            >
              Back to Sustainability Institute
            </Typography>
          </ButtonBase>
          {user.status === "success" ? (
            <Fragment>
              <ButtonBase
                aria-label="Navigate to Programs"
                component={RouterLink}
                to="/programs"
              >
                <Typography
                  classes={{ root: classes.tabs }}
                  className="pr-2"
                  variant="h2"
                  component="span"
                  noWrap
                >
                  Programs
                </Typography>
              </ButtonBase>
              <img
                className="buckeyeNut"
                alt="Buckeye Smooth Logo"
                src={
                  darkMode
                    ? buckeyeSmoothScarletWhite
                    : buckeyeSmoothScarletWhite
                }
              />
              <ButtonBase
                aria-label="Navigate to Departments"
                component={RouterLink}
                to="/departments"
              >
                <Typography
                  classes={{ root: classes.tabs }}
                  className="px-2 d-flex flex-column"
                  variant="h2"
                  component="span"
                  noWrap
                >
                  Departments
                </Typography>
              </ButtonBase>
              <img
                className="buckeyeNut"
                alt="Buckeye Smooth Logo"
                src={
                  darkMode
                    ? buckeyeSmoothScarletWhite
                    : buckeyeSmoothScarletWhite
                }
              />
              <ButtonBase
                aria-label="Navigate to Keywords"
                component={RouterLink}
                to="/keywords"
              >
                <Typography
                  classes={{ root: classes.title }}
                  className="px-2 d-flex flex-column"
                  variant="h6"
                  component="span"
                  noWrap
                >
                  Keywords
                </Typography>
              </ButtonBase>
              <img
                className="buckeyeNut"
                alt="Buckeye Smooth Logo"
                src={
                  darkMode
                    ? buckeyeSmoothScarletWhite
                    : buckeyeSmoothScarletWhite
                }
              />
              <ButtonBase
                aria-label="Navigate to Colleges"
                component={RouterLink}
                to="/colleges"
              >
                <Typography
                  classes={{ root: classes.title }}
                  className="px-2 d-flex flex-column"
                  variant="h6"
                  component="span"
                  noWrap
                >
                  Colleges
                </Typography>
              </ButtonBase>
              <img
                className="buckeyeNut"
                alt="Buckeye Smooth Logo"
                src={
                  darkMode
                    ? buckeyeSmoothScarletWhite
                    : buckeyeSmoothScarletWhite
                }
              />
              <ButtonBase
                aria-label="Navigate to Interests"
                component={RouterLink}
                to="/interests"
              >
                <Typography
                  classes={{ root: classes.title }}
                  className="pl-1 d-flex flex-column"
                  variant="h6"
                  component="span"
                  noWrap
                >
                  Interests
                </Typography>
              </ButtonBase>
              {props.children}
            </Fragment>
          ) : (
            ""
          )}
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;

AppBar.defaultProps = {
  className: "",
  elevation: 0,
  determineHeight: (height) => ({ height }),
};

AppBar.propTypes = {
  elevation: PropTypes.number,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  determineHeight: PropTypes.func,
};
