import React, { Fragment, useState, Suspense, lazy } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import SignInContainer from "../../Authentication/containers/SignIn";
import SignOut from "../../Authentication/components/SignOut";
import Home from "../../Components/Home";
import Students from "../../Components/Students";
import Keywords from "../../Components/Keywords";
import Colleges from "../../Components/Colleges";
import Programs from "../../Components/Programs";
import EditProgram from "../../Components/Programs/components/EditProgram";
import Departments from "../../Components/Departments";
import EditDepartment from "../../Components/Departments/components/EditDepartment";
import AddDepartment from "../../Components/Departments/components/AddDepartment";

import AddStudent from "../../Components/Students/components/AddStudent";
// import NoComponentFound from './NoComponentFound';
function AppRoutes({ mobile = false }) {
  const AddKeyword = lazy(() =>
    import("../../Components/Keywords/components/AddKeyword")
  );
  const EditKeyword = lazy(() =>
    import("../../Components/Keywords/components/EditKeyword")
  );
  const AddCollege = lazy(() =>
    import("../../Components/Colleges/components/AddCollege")
  );
  const EditCollege = lazy(() =>
    import("../../Components/Colleges/components/EditCollege")
  );
  const AddProgram = lazy(() =>
    import("../../Components/Programs/components/AddProgram")
  );
  const [title, setTitle] = useState("");
  const history = useHistory();
  const mobileOnlyRoutes = ["about-you"];
  const createTitle =
    (page = "") =>
    (description = "") => {
      setTitle(`${page}${description ? ` | ${description}` : ""}`);
    };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.length]);

  const routes = (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {!mobile &&
          mobileOnlyRoutes.map((path, index) => (
            <Redirect key={index} from={`/${path}`} to="/" />
          ))}
        <Route
          exact
          path="/"
          render={() =>
            mobile ? (
              <Home generateTitle={createTitle("SELC Portal")} />
            ) : (
              <Home generateTitle={createTitle("SELC Portal")} />
            )
          }
        />
        <Route
          exact
          path="/login"
          render={() => (
            <SignInContainer
              generateTitle={createTitle("SELC Portal Sign In")}
            />
          )}
        />
        <Route
          exact
          path="/logout"
          render={() => (
            <SignOut generateTitle={createTitle("SELC Portal Sign Out")} />
          )}
        />
        <Route
          path="/addkeyword"
          render={() => (
            <AddKeyword
              generateTitle={createTitle("Selc Portal | Add Keyword")}
            />
          )}
        />
        <Route
          path="/keyword/:keywordid"
          render={(props) => (
            <EditKeyword
              generateTitle={createTitle("Selc Portal | Edit Keyword")}
              keywordid={props.match.params.keywordid}
            />
          )}
        />
        <Route
          path="/college/addcollege"
          render={() => (
            <AddCollege
              generateTitle={createTitle("Selc Portal | Add College")}
            />
          )}
        />
        <Route
          path="/college/:collegeid"
          render={(props) => (
            <EditCollege
              generateTitle={createTitle("Selc Portal | Edit College")}
              collegeid={props.match.params.collegeid}
            />
          )}
        />
        <Route path="/students" component={Students} />
        <Route
          path="/addstudent"
          render={() => (
            <AddStudent generateTitle={createTitle("SELC | Add Student")} />
          )}
        />
        <Route path="/Keywords" component={Keywords} />

        <Route path="/Colleges" component={Colleges} />
        <Route path="/Programs" component={Programs} />
        <Route
          path="/program/:programid"
          render={(props) => (
            <EditProgram
              generateTitle={createTitle("Selc Portal | Edit Program")}
              programid={props.match.params.programid}
            />
          )}
        />
        <Route
          path="/addProgram"
          render={() => (
            <AddProgram
              generateTitle={createTitle("Selc Portal | Add Program")}
            />
          )}
        />
        <Route
          path="/addDepartment"
          render={() => (
            <AddDepartment
              generateTitle={createTitle("Selc Portal | Add Department")}
            />
          )}
        />
        <Route
          path="/department/:departmentid"
          render={(props) => (
            <EditDepartment
              generateTitle={createTitle("Selc Portal | Edit Department")}
              departmentid={props.match.params.departmentid}
            />
          )}
        />
        <Route path="/Departments" component={Departments} />

        {/* <Route
          path="/addcollege"
          render={() => (
            <AddCollege generateTitle={createTitle("SELC | Add College")} />
          )}
        /> */}
        {/* <Route render={() => < NoComponentFound />} /> */}
      </Switch>
    </Suspense>
  );

  return (
    <Fragment>
      <Helmet>{!!title && <title>{title}</title>}</Helmet>
      {routes}
    </Fragment>
  );
}

export default AppRoutes;
