import API from "@aws-amplify/api";
// import orderBy from "lodash/orderBy";
import { GetCurrentDateTime, generateRowId } from "../../util/util";

export async function GetStudentData() {
  var paramsContact = {
    operation: "read",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Student",
      },
    },
  };
  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsContact,
    }
  );
  // const responseData = orderBy(
  // 	response.Items,
  // 	["IsOrganization","LastName", "FirstName"],
  // 	["desc", "asc", "asc"]
  // );
  // responseData.map(function (tcid) {
  // 	Object.keys(tcid).forEach((key) => {
  // 		if (tcid[key] === "NULL") {
  // 			tcid[key] = "";
  // 		}
  // 	});
  // 	return tcid;
  // });
  return response.Items;
}
export async function AddStudent(studentdata, user) {
  var paramsUpdate = {
    operation: "create",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Item: {
        pk: "student-" + generateRowId(4),
        sk: "Student",
        data: studentdata.data,
        StudentName: studentdata.StudentName,
        Year: studentdata.Year,
        Major: studentdata.Minor,
        Description: studentdata.Description,
        IsActive: studentdata.IsActive,
        IsDeleted: studentdata.IsDeleted,
        LastModifiedBy: user,
        LastModifiedDate: GetCurrentDateTime(),
      },
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      ReturnValues: "ALL_OLD",
    },
  };
  await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
    body: paramsUpdate,
  });
  return;
}
