import React, { Component } from "react";
import API from "@aws-amplify/api";
import "../styles/index.css";
import EditIcon from "@material-ui/icons/Edit";
import {
  Typography,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Switch,
  TextField,
  Card,
  Grid,
  Paper,
} from "@material-ui/core";
import { Form, Confirm } from "semantic-ui-react";
import moment from "moment";
import { GetCurrentDateTime } from "../../../util/util";
import Notifier, { openSnackbar } from "../../../Common/components/Notifier";
import {
  GetKeywords,
  GetProgramByID,
  GetKeywordData,
  GetColleges,
  GetDepartments,
  GetCareerPaths,
} from "../action";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import KeywordTable from "./KeywordTable";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";

export default class EditProgram extends Component {
  _isMounted = false;
  _redirect = "/programs";

  constructor(props) {
    super(props);
    props.generateTitle();
    this.programid = this.props.programid;
    this.setDataReceivedFromChild = this.setDataReceivedFromChild.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.updateProgram = this.updateProgram.bind(this);
    this.UpdateKeywords = this.UpdateKeywords.bind(this);
    this.deleteProgram = this.deleteProgram.bind(this);
    this.showNotifier = this.showNotifier.bind(this);
    this.handleSelectMulti = this.handleSelectMulti.bind(this);
    this.state = {
      currentProgram: {
        pk: null,
        data: "",
        College: "",
        Department: "",
      },
      careerPathList: [],
      keywordList: [],
      collegeList: [],
      deptList: [],
      isLoading2: true,
      showAll: false,
      updated: false,
      message: "",
      open: false,
    };
    this.user = sessionStorage.getItem("UserName");
  }

  async componentDidMount() {
    this._isMounted = true;
    await GetKeywords(this.props.programid).then((response) =>
      this.setState({ keywordList: response })
    );
    await GetCareerPaths(this.props.programid).then((response) =>
      this.setState({ careerPathList: response })
    );

    await GetKeywordData().then((response) =>
      this.setState({
        allkeywordslist: response.filter(
          (keyword) =>
            !this.state.keywordList.map((item) => item.pk).includes(keyword.pk)
        ),
      })
    );
    await GetColleges().then((response) =>
      this.setState({ collegeList: response })
    );
    await GetDepartments().then((response) =>
      this.setState({ deptList: response })
    );

    await GetProgramByID(this.props.programid).then((response) =>
      this.setState({ currentProgram: response, isLoading2: false })
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showNotifier(messageString) {
    openSnackbar({ message: messageString });
  }

  getRedirect() {
    return this._redirect;
  }
  async setDataReceivedFromChild(index) {
    this.setState({ keywordList: index.filter((e) => e.Selected === true) });
  }
  handleChange(e, { id, value }) {
    this.setState(
      {
        currentProgram: Object.assign({}, this.state.currentProgram, {
          [id]: value,
        }),
      },
      () => {}
    );
  }
  handleSelectMulti(e) {
    console.log(e);
  }
  handleDateChange(e, value) {
    this.setState(
      {
        currentProgram: Object.assign({}, this.state.currentProgram, {
          [e]: moment(value).format("YYYY-MM-DD"),
        }),
      },
      () => {}
    );
  }
  handleCheckboxChange(e, { id }) {
    this.setState(
      {
        currentProgram: Object.assign({}, this.state.currentProgram, {
          [id]: e.target.checked,
        }),
      },
      () => {}
    );
  }

  async updateProgram() {
    const { currentProgram, keywordList } = this.state;
    if (currentProgram.data.trim().length > 0) {
      var paramsUpdate = {
        operation: "update",
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        payload: {
          TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,

          Key: {
            pk: this.programid,
            sk: "Program",
          },

          UpdateExpression:
            "set  #data=:d, College=:coll, Department=:dept, LastModifiedBy=:user, LastModifiedDate=:date",
          ExpressionAttributeNames: {
            "#data": "data",
          },

          ExpressionAttributeValues: {
            ":d": currentProgram.data,
            ":user": this.user,
            ":date": GetCurrentDateTime(),
            ":coll": currentProgram.College,
            ":dept": currentProgram.Department,
          },

          ReturnValues: "UPDATED_NEW",
        },
      };

      API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
        body: paramsUpdate,
      });
      await this.UpdateKeywords(keywordList);
      return <Redirect to={this._redirect} />;
    } else {
      this.showNotifier("Please type in a valid program");
      this.setState({ redirect: false });
      this._redirect = "/Programs/" + this.programid;
    }
  }

  async UpdateKeywords(keywords) {
    //get all keyword-program joins
    var paramsProg = {
      operation: "read",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      payload: {
        IndexName: "sk-data-index",
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        KeyConditionExpression: "sk = :progid and #data = :objecttype",
        ExpressionAttributeValues: {
          ":progid": this.props.programid,
          ":objecttype": "KeywordProgram",
        },
        ExpressionAttributeNames: {
          "#data": "data",
        },
      },
    };
    const response = await API.post(
      process.env.REACT_APP_SELC_PORTAL_API_NAME,
      "/items",
      {
        body: paramsProg,
      }
    );
    if (response.Items) {
      //delete if not in new list of keyword program joins response.Items.forEach(async (element) =>
      for (let element of response.Items) {
        if (!keywords.map((e) => e.pk).includes(element.pk)) {
          var paramsDelete = {
            operation: "delete",
            TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
            payload: {
              TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
              Key: {
                pk: element.pk,
                sk: this.props.programid,
              },
            },
          };
          await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
            body: paramsDelete,
          });
        }
      }
    }
    // await keywords.forEach(async (keyword) =>
    for (let keyword of keywords) {
      var paramsUpdate = {
        operation: "create",
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        payload: {
          Item: {
            pk: keyword.pk,
            sk: this.props.programid,
            data: "KeywordProgram",
            LastModifiedBy: this.user,
            LastModifiedDate: GetCurrentDateTime(),
          },
          TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
          ReturnValues: "ALL_OLD",
        },
      };
      await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
        body: paramsUpdate,
      });
    }
    this.setState({
      currentProgram: this.state.currentProgram,
      isLoading2: false,
    });

    this.showNotifier("Program Updated");
    this.setState({ redirect: true });
    this._redirect = "/Programs";
  }

  deleteProgram() {
    var paramsDelete = {
      operation: "delete",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      payload: {
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        Key: {
          pk: this.programid,
          sk: "Program",
        },
      },
    };

    API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
      body: paramsDelete,
    }).then(() => {
      this.setState({
        currentProgram: this.state.currentProgram,
        isLoading2: false,
      });

      this._redirect = "/Programs";
      this.showNotifier("Program Deleted!");
    });
  }

  show = () => this.setState({ open: true });
  handleConfirm = () => this.setState({ open: false });
  handleCancel = () => this.setState({ open: false });

  render() {
    if (this.state.isLoading2) {
      return <Typography variant="h1">Loading Program...</Typography>;
    } else {
      var { currentProgram, keywordList, allkeywordslist, showAll } =
        this.state;
      const programRedirect = this.getRedirect();
      const collegeList = this.state.collegeList.map((coll) => ({
        value: coll.pk,
        text: coll.data,
        key: coll.pk,
      }));
      const deptList = this.state.deptList.map((dept) => ({
        value: dept.pk,
        text: dept.data,
        key: dept.pk,
      }));
      const careerPathList = this.state.careerPathList.map((careerpath) => ({
        value: careerpath.pk,
        text: careerpath.Name,
        key: careerpath.pk,
        label: careerpath.Name,
        isSelected: careerpath.isSelected
      }));
      //case-insensitive sorting
      keywordList = keywordList
        ? _.orderBy(keywordList, [(key) => key.KeyName.toLowerCase()], ["asc"])
        : [];
      allkeywordslist = allkeywordslist
        ? _.orderBy(
            allkeywordslist,
            [(key) => key.KeyName.toLowerCase()],
            ["asc"]
          )
        : [];
      const animatedComponents = makeAnimated();

      return currentProgram ? (
        <div className="edit-form">
          <Typography variant="h1">
            <EditIcon />
            Edit Program
          </Typography>
          {
            <Form>
              <hr className="scarlet"></hr>
              {/* <Card> */}
              <Form.Group>
                <Form.Input
                  label="Program Name"
                  placeholder="Program"
                  className="form-control"
                  id="data"
                  value={currentProgram.data}
                  onChange={this.handleChange}
                  width={6}
                />
              </Form.Group>
              <Form.Group>
                <Form.Select
                  label="College"
                  name="College"
                  search
                  className="form-control"
                  value={currentProgram.College}
                  options={collegeList}
                  placeholder="College"
                  onChange={this.handleChange}
                  onAddItem={this.handleAddition}
                  id="College"
                />
              </Form.Group>
              <Form.Group>
                <Form.Select
                  label="Department/School"
                  name="Department/School"
                  search
                  className="form-control"
                  value={currentProgram.Department}
                  options={deptList}
                  placeholder="Department/School"
                  onChange={this.handleChange}
                  onAddItem={this.handleAddition}
                  id="Department"
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Primary Contact Name"
                  placeholder="Contact Name"
                  className="form-control"
                  id="PrimaryContactName"
                  value={currentProgram.PrimaryContactName}
                  onChange={this.handleChange}
                  width={6}
                />
                <Form.Input
                  label="Primary Contact Email"
                  placeholder="Contact Email"
                  className="form-control"
                  id="PrimaryContactEmail"
                  value={currentProgram.PrimaryContactEmail}
                  onChange={this.handleChange}
                  width={6}
                />
                <Form.Input
                  label="Primary Contact Phone Number"
                  placeholder="Contact Phone Number"
                  className="form-control"
                  id="PhoneNumber"
                  value={currentProgram.PhoneNumber}
                  onChange={this.handleChange}
                  width={6}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Reporting Contact Email"
                  placeholder="Program"
                  className="form-control"
                  id="ReportingContact"
                  value={currentProgram.ReportingContact}
                  onChange={this.handleChange}
                  width={6}
                />
              </Form.Group>
              <Form.Group>
                <TextField
                  id="Description"
                  value={currentProgram.Description}
                  label="Program Description"
                  InputLabelProps={{ shrink: false }}
                  multiline
                  rows={5}
                  fullWidth={true}
                />
              </Form.Group>
              <FormGroup>
                <label>Keywords</label>
                {showAll ? (
                  <KeywordTable
                    allkeywords={keywordList.concat(allkeywordslist)}
                    receiveDataFromChild={this.setDataReceivedFromChild}
                  />
                ) : (
                  <List dense={true}>
                    {keywordList.map((e) => (
                      <ListItem key={e.pk} button={true}>
                        <ListItemText primary={e.KeyName} />
                      </ListItem>
                    ))}
                  </List>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAll}
                      onChange={() => {
                        this.setState((prevState) => ({
                          showAll: !prevState.showAll,
                        }));
                      }}
                      name="checkedA"
                    />
                  }
                  label={
                    showAll
                      ? "View Only Selected Keywords"
                      : "View All Available Keywords"
                  }
                />
                {/* <ToggleButton value={showAll}
                selected={showAll}
                onChange={()=>{this.setState(prevState=>({showAll:!prevState.showAll}))}} */}
              </FormGroup>
              <label>Career Paths</label>
              {"\n"}
              {"\n"}
              {"\n"}
              {/* <Form.Group>
              <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
              <div className="col-md-6"> */}
              <Select
                options={careerPathList}
                components={animatedComponents}
                isMulti
                onChange={this.handleSelectMulti}
                value={careerPathList}
                onCreateOption={console.log(this)}
              />
              {/* </div>
                          <div className="col-md-4"></div>
        </div>
      </div> */}
              {/* <Grid container spacing={6}>
                  {careerPathList.map((e) => (
                    <Grid item key={e.pk} >
                      <Paper primary={e.CareerName} className="paper">
                        {e.CareerName}
                      </Paper>
                    </Grid>
                  ))}
                </Grid> */}
              {/* </Form.Group> */}
              <br />
              <Form.Group inline>
                <Form.Button
                  type="submit"
                  onClick={this.updateProgram}
                  className="badge badge-success"
                >
                  Update
                </Form.Button>

                <Form.Button className="badge badge-danger" onClick={this.show}>
                  Delete
                </Form.Button>
                <Confirm
                  id="confirmDialogBox"
                  open={this.state.open}
                  header="Deletions can not be undone"
                  cancelButton="Never mind"
                  confirmButton="Let's do it"
                  onCancel={this.handleCancel}
                  onConfirm={() => (this.handleConfirm, this.deleteProgram)}
                />
                <Link to={{ pathname: "/Programs" }}>
                  <Form.Button>Cancel</Form.Button>
                </Link>
              </Form.Group>
              <Link to={{ pathname: `${programRedirect}` }}>
                <Notifier />
              </Link>
              {/* </Card> */}
            </Form>
          }
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Program...</p>
        </div>
      );
    }
  }
}
