import API from "@aws-amplify/api";
// import orderBy from "lodash/orderBy";
import { GetCurrentDateTime, generateRowId } from "../../util/util";

export async function GetCollegeByCollegeID(collegeId) {
  var collegeData = {};
  var paramsCollege = {
    operation: "get",
    tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Key: {
        pk: collegeId,
        sk: "College",
      },
      ExpressionAttributeNames: {
        "#data": "data",
      },
      ProjectionExpression: "pk,  #data",
    },
  };
  await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
    body: paramsCollege,
  }).then((response) => (collegeData = response.Item));

  return collegeData;
}

export async function GetCollegeData() {
  var paramsCollege = {
    operation: "read",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      IndexName: "sk-data-index",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "College",
      },
    },
  };

  const response = await API.post(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/items",
    {
      body: paramsCollege,
    }
  );

  // const responseData = orderBy(
  // 	response.Items,
  // 	["IsOrganization","LastName", "FirstName"],
  // 	["desc", "asc", "asc"]
  // );
  // responseData.map(function (tcid) {
  // 	Object.keys(tcid).forEach((key) => {
  // 		if (tcid[key] === "NULL") {
  // 			tcid[key] = "";
  // 		}
  // 	});
  // 	return tcid;
  // });
  console.log(response);
  return response.Items;
}

export async function AddCollege(collegedata, user) {
  var paramsUpdate = {
    operation: "create",
    TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
    payload: {
      Item: {
        pk: "College-" + generateRowId(4),
        sk: "College",
        data: collegedata.data,
        LastModifiedBy: user,
        LastModifiedDate: GetCurrentDateTime(),
      },
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      ReturnValues: "ALL_OLD",
    },
  };

  await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
    body: paramsUpdate,
  });

  return;
}
