import React, { useEffect } from "react";
import { Typography, Box, Container } from "@material-ui/core";
import "../styles/index.scss";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

function EmergencyAlert(props = {}) {
	const { getEmergencyAlert, feed, className } = props;
	useEffect(() => {
		getEmergencyAlert();
	}, [getEmergencyAlert]);

	if (feed.length === 0) return null;
	return (
		<div role="alert" aria-live="polite">
			<Typography variant="h2" className="h-accessible-text">
				Emergency Alert Message
			</Typography>
			{feed.map((item, index) => {
				const { content, id } = item;
				const sanitizedHtmlContent = sanitizeHtml(content, {
					transformTags: {
						a: sanitizeHtml.simpleTransform("a", {
							target: "_blank",
							rel: "noopener",
						}),
					},
				});

				return (
					<Box
						key={`emergency-alert-${index}-${id}`}
						className={`emergency-alert ${className}`.trim()}
					>
						<Container>
							<Typography
								component="div"
								variant="h6"
								className={`text-wrapper`}
								dangerouslySetInnerHTML={{
									__html: sanitizedHtmlContent,
								}}
							/>
						</Container>
					</Box>
				);
			})}
		</div>
	);
}

EmergencyAlert.defaultProps = {
	feed: [],
	className: "",
	getEmergencyAlert: () => {},
};

EmergencyAlert.propTypes = {
	feed: PropTypes.array,
	className: PropTypes.string,
	getEmergencyAlert: PropTypes.func,
};

export default EmergencyAlert;
