import API from "@aws-amplify/api";
// import orderBy from "lodash/orderBy";
import { GetCurrentDateTime, generateRowId } from "../../util/util";


export async function GetKeywordByKeywordID(keywordId) {
	var keywordData = {};
	var paramsKeyword = {
		operation: "get",
		tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
		payload: {
			Key: {
				pk: keywordId,
				sk: "Keyword",
			},
			ExpressionAttributeNames: {
				"#data": "data",
			},
			ProjectionExpression:
				"pk,  #data",
		},
	};
	await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
		body: paramsKeyword,
	}).then((response) => (keywordData = response.Item));

	return keywordData;
}
export async function GetKeywordData() {
    var paramsKeyword = {
      operation: "read",
      tableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      payload: {
        IndexName: "sk-data-index",
        KeyConditionExpression: "sk = :objecttype",
        ExpressionAttributeValues: {
          ":objecttype": "Keyword",
        },
      },
    };
    const response = await API.post(
      process.env.REACT_APP_SELC_PORTAL_API_NAME,
      "/items",
      {
        body: paramsKeyword,
      }
    );
    // const responseData = orderBy(
    // 	response.Items,
    // 	["IsOrganization","LastName", "FirstName"],
    // 	["desc", "asc", "asc"]
    // );
    // responseData.map(function (tcid) {
    // 	Object.keys(tcid).forEach((key) => {
    // 		if (tcid[key] === "NULL") {
    // 			tcid[key] = "";
    // 		}
    // 	});
    // 	return tcid;
    // });
    return response.Items;
  }
  export async function AddKeyword(keyworddata, user) {
    var paramsUpdate = {
      operation: "create",
      TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
      payload: {
        Item: {
          pk: "keyword-" + generateRowId(4),
          sk: "Keyword",
          data: keyworddata.data,
          LastModifiedBy: user,
          LastModifiedDate: GetCurrentDateTime(),
        },
        TableName: process.env.REACT_APP_SELC_PORTAL_TABLE_NAME,
        ReturnValues: "ALL_OLD",
      },
    };
    await API.post(process.env.REACT_APP_SELC_PORTAL_API_NAME, "/items", {
      body: paramsUpdate,
    });
    return;
  }
  