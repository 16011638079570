import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from "@material-ui/core/Button";


let openSnackbarFn

class Notifier extends React.Component {
    
     // set the Notifier's initial state
    state = {
        open: false,
        message: '',
      };

      componentDidMount() {
        openSnackbarFn = this.openSnackbar;
      }
   
    //  define a function to open Snackbar and show a message
    openSnackbar = ({ message }) => {
        this.setState({
          open: true,
          message,
        });
      };

    // define a function to close Snackbar when a user clicks away
    handleSnackbarClose = () => {
        this.setState({
          open: false,
          message: '',
        });
      };

  render() {
    //show a message to users
    const message = (
        <span
          id="snackbar-message-id"
          dangerouslySetInnerHTML={{ __html: this.state.message }}
        />
      );

      const action = (
          <Button color={"secondary"} size="small">
            Ok!
          </Button>
        ); 

  return (
    <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    message={message}
    action={action}
    autoHideDuration={3000}
    onClick={this.handleSnackbarClose}
    open={this.state.open}
    ContentProps={{
      'aria-describedby': 'snackbar-message-id',
    }}
  />
      );
    }
  }


  export function openSnackbar({ message }) {
    openSnackbarFn({ message });
  }

  export default Notifier;
